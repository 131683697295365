<template>
  <div v-if="versionMobile" class="carousel-featured hide-for-print">
    <div class="carousel-mobile-version">
      <slot />
    </div>
  </div>
  <div v-else>
    <carousel>
      <slot />
    </carousel>
  </div>
</template>

<script>
import Carousel from "./Carousel.vue";

export default {
  name: "CarouselFeatured",
  components: {
    Carousel,
  },
  data() {
    return {
      versionMobile: false,
      mediaQueryMobile: "",
    };
  },
  mounted() {
    this.mediaQueryMobile = window.matchMedia("(max-width: 767.98px)");
    this.onResize();
    window.addEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      this.versionMobile = this.mediaQueryMobile.matches;
    },
  },
};
</script>

<style>
[v-cloak] {
  display: none;
}
</style>
