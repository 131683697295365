<template>
  <div>
    <form class="subscribe-form" @submit.prevent="makeSubscription">
      <label class="label has-text-weight-semibold mb-0"
        >Subscribe to ACC SmartBrief
      </label>
      <div
        class="help is-danger fit-height-notification-message"
        v-if="errorInForm"
      >
        {{ messageValidation }}
      </div>
      <div
        v-if="previousSubmit && !errorInForm"
        class="help fit-height-notification-message"
      />
      <div
        v-if="!previousSubmit"
        class="help fit-height-notification-message"
        :class="[successSubmit ? 'is-success' : 'is-danger']"
        v-html="messageResponse"
      />
      <div class="control">
        <input
          class="input"
          type="text"
          placeholder="Your email address"
          v-model="email"
          @keyup="isValidInputData()"
          :disabled="disabledElementsForm"
        />
        <button
          class="button is-link is-small"
          type="submit"
          :disabled="disabledElementsForm"
        >
          Submit
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "SmartBriefSubscription",
  props: {
    endPoint: {
      type: String,
      required: true,
    },
    showState: {
      type: String,
      default: "0",
    },
    mockedMode: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      email: "",
      successSubmit: false,
      messageResponse: "",
      errorInForm: false,
      messageValidation: "",
      unexpectedError: "An unexpected error has occurred",
      previousSubmit: true,
      disabledElementsForm: false,
    };
  },
  mounted() {
    if (this.showState == "1") {
      this.successSubmit = true;
      this.messageResponse =
        "Thank you for suscribing to our Daily Industry News SmartBrief";
    }

    if (this.showState == "2") {
      this.errorSubmit = true;
      this.messageResponse = `Sorry, We couldn't add your email to the subscription list. Please try again!`;
    }
  },
  methods: {
    emailIsValid(email) {
      email = email.toLowerCase();
      return /\S+@\S+\.\S+/.test(email);
    },
    isValidInputData() {
      if (this.email.length === 0) {
        this.errorInForm = true;
        this.messageValidation = "Email is required";
        return false;
      }

      if (!this.emailIsValid(this.email)) {
        this.errorInForm = true;
        this.messageValidation = "You must enter a valid email";
      } else {
        this.errorInForm = false;
        this.messageValidation = "";
      }
      return !this.errorInForm;
    },
    async makeSubscription() {
      if (!this.isValidInputData()) {
        return;
      }

      if (this.mockedMode) {
        try {
          const response = await axios.get(this.endPoint);
          if (response.data.success) {
            this.successFormProcessed(response.data.message);
          } else {
            this.errorFormProcessed(response.data.message);
          }
        } catch (error) {
          this.errorFormProcessed(this.unexpectedError);
        }
        this.disabledElementsForm = true;
        setTimeout(() => {
          this.resetForm();
        }, 10000);
        return;
      }

      try {
        const response = await axios.post(this.endPoint, { email: this.email });
        if (response.data.success) {
          this.successFormProcessed(response.data.message);
        } else {
          this.errorFormProcessed(response.data.message);
        }
      } catch (error) {
        this.errorFormProcessed(this.unexpectedError);
      }
      this.disabledElementsForm = true;
      setTimeout(() => {
        this.resetForm();
      }, 10000);
    },
    resetForm() {
      this.successSubmit = false;
      this.email = "";
      this.previousSubmit = true;
      this.disabledElementsForm = false;
    },
    successFormProcessed(message) {
      this.previousSubmit = false;
      this.successSubmit = true;
      this.messageResponse = message;
    },
    errorFormProcessed(message) {
      this.previousSubmit = false;
      this.successSubmit = false;
      this.messageResponse = message;
    },
  },
};
</script>


<style scoped>
.fit-height-notification-message {
  height: 20px;
}
</style>
